import { createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import { handleGetProducts } from 'src/services/product';
// utils
import axios from '../../utils/axios';
import initial from './initial';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    productsIsLoading: false,
    groupProductsIsLoading: false,
    productGroupsIsLoading: false,
    productGroupIsLoading: false,
    inventoryIsLoading: false,
    productIsLoading: false,

    // OPTIONS
    countriesLoading: false,
    productGroupsOptionsIsLoading: false
  },
  error: {
    productsError: null,
    groupProductsError: null,
    productGroupsError: null,
    productGroupError: null,
    inventoryError: null,
    productError: null,

    // OPTIONS
    countriesError: null,
    productGroupsOptionsError: null
  },
  products: initial.pagableData,
  groupProducts: initial.pagableData,
  productGroups: initial.pagableData,
  productGroup: initial.pagableData,
  inventory: initial.pagableData,
  product: null,
  countries: [],
  productGroupsOptions: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startProductsLoading(state) {
      state.loading.productsIsLoading = true;
    },
    startGroupProductsLoading(state) {
      state.loading.groupProductsIsLoading = true;
    },
    stopGroupProductsLoading(state) {
      state.loading.groupProductsIsLoading = false;
    },
    startProductGroupsLoading(state) {
      state.loading.productGroupsIsLoading = true;
    },
    startInventoryLoading(state) {
      state.loading.inventoryIsLoading = true;
    },
    startProductLoading(state) {
      state.loading.productIsLoading = true;
    },
    startProductGroupLoading(state) {
      state.loading.productGroupIsLoading = true;
    },
    startCountriesLoading(state) {
      state.loading.countriesLoading = true;
    },
    startProductGroupsOptionsLoading(state) {
      state.loading.productGroupsOptionsIsLoading = true;
    },

    // HAS ERROR
    hasProductsError(state, action) {
      state.loading.productsIsLoading = false;
      state.error.productsError = action.payload;
    },
    hasGroupProductsError(state, action) {
      state.loading.groupProductsIsLoading = false;
      state.error.groupProductsError = action.payload;
    },
    hasProductGroupsError(state, action) {
      state.loading.productGroupsIsLoading = false;
      state.error.productGroupsError = action.payload;
    },
    hasInventoryError(state, action) {
      state.loading.inventoryIsLoading = false;
      state.error.inventoryError = action.payload;
    },
    hasProductError(state, action) {
      state.loading.productIsLoading = false;
      state.error.productError = action.payload;
    },
    hasProductGroupError(state, action) {
      state.loading.productGroupIsLoading = false;
      state.error.productGroupError = action.payload;
    },
    hasCountriesError(state, action) {
      state.loading.countriesLoading = false;
      state.error.countriesError = action.payload;
    },
    hasProductGroupsOptionsError(state, action) {
      state.loading.productGroupsOptionsIsLoading = false;
      state.error.productGroupsOptionsError = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.loading.productsIsLoading = false;
      state.products = action.payload;
    },
    getGroupProductsSuccess(state, action) {
      state.loading.groupProductsIsLoading = false;
      state.groupProducts = action.payload;
    },
    // GET PRODUCT GROUPS
    getProductGroupsSuccess(state, action) {
      state.loading.productGroupsIsLoading = false;
      state.productGroups = action.payload;
    },
    getProductGroupSuccess(state, action) {
      state.loading.productGroupIsLoading = false;
      state.productGroup = action.payload;
    },

    // GET INVENTORY
    getInventorySuccess(state, action) {
      state.loading.inventoryIsLoading = false;
      state.inventory = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.loading.productIsLoading = false;
      state.product = action.payload;
    },

    // GET OPTIONS
    getCountriesSuccess(state, action) {
      state.loading.countriesLoading = false;
      state.countries = action.payload;
    },
    getProductGroupsOptionsSuccess(state, action) {
      state.loading.productGroupsOptionsIsLoading = false;
      state.productGroupsOptions = action.payload;
    },

    // RESET
    resetProducts(state) {
      state.loading.productsIsLoading = false;
      state.products = initial.pagableData;
    },
    resetInventory(state) {
      state.loading.inventoryIsLoading = false;
      state.inventory = initial.pagableData;
    },
    resetGroupProducts(state) {
      state.loading.groupProductsIsLoading = false;
      state.groupProducts = initial.pagableData;
    },
    resetProduct(state) {
      state.loading.productIsLoading = false;
      state.product = null;
    },
    resetGroups(state) {
      state.loading.productGroupsIsLoading = false;
      state.productGroups = initial.pagableData;
    },
    resetGroup(state) {
      state.loading.productGroupIsLoading = false;
      state.productGroup = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  resetProducts,
  resetGroupProducts,
  resetProduct,
  resetInventory,
  resetGroups,
  resetGroup,
  startGroupProductsLoading,
  stopGroupProductsLoading,
  getGroupProductsSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(page, rowsPerPage, orderBy, order, filterName, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductsLoading());
    try {
      const response = await handleGetProducts(page, rowsPerPage, orderBy, order, filterName, status);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasProductsError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductsByGroup(page, size, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startGroupProductsLoading());
    try {
      const response = await axios.get(`/manage/groups/${id}/products`, {
        params: {
          page,
          size
        }
      });
      dispatch(slice.actions.getGroupProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGroupProductsError(error));
    }
  };
}

export async function deleteGroupProduct(groupId, productId, page, size) {
  return axios.delete(`/manage/groups/${groupId}/products/${productId}`, {
    params: {
      page,
      size
    }
  });
}

export async function addGroupProduct(groupId, productId, page, size) {
  return axios.put(`/manage/groups/${groupId}/products/${productId}`, null, {
    params: {
      page,
      size
    }
  });
}

// ----------------------------------------------------------------------

export function getProductGroups(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductGroupsLoading());
    try {
      const response = await axios.get('/manage/groups', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getProductGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasProductGroupsError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventory(page, rowsPerPage, orderBy, order, filterName, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startInventoryLoading());
    try {
      const response = await axios.get('/manage/inventory', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName,
          status
        }
      });
      dispatch(slice.actions.getInventorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasInventoryError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductLoading());
    try {
      const response = await axios.get(`/manage/products/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasProductError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductGroupLoading());
    try {
      const response = await axios.get(`/manage/groups/${id}`);
      dispatch(slice.actions.getProductGroupSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasProductGroupError(error));
    }
  };
}

export async function createProduct(values) {
  return axios.post('/manage/products', values);
}

export async function createProductGroup(values) {
  const formData = serialize(values, {
    indices: true,
    allowEmptyArrays: true,
    noAttributesWithArrayNotation: true,
    dotsForObjectNotation: true,
    nullsAsUndefineds: true
  });
  return axios.post('/manage/groups', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function updateProduct(id, values) {
  return axios.put(`/manage/products/${id}`, values);
}

export async function updateProductGroup(id, values) {
  const formData = serialize(values, {
    indices: true,
    allowEmptyArrays: true,
    noAttributesWithArrayNotation: true,
    dotsForObjectNotation: true,
    nullsAsUndefineds: true
  });
  return axios.put(`/manage/groups/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function uploadCoverImage(id, coverImageFile, coverImage) {
  const formData = serialize(
    {
      image: coverImage,
      imageFile: coverImageFile
    },
    {
      indices: true,
      noAttributesWithArrayNotation: true,
      dotsForObjectNotation: true,
      nullsAsUndefineds: true
    }
  );

  return axios.post(`/manage/products/${id}/images/cover`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function uploadImages(id, images) {
  let formData = new FormData();
  images.forEach((_image) => {
    formData.append('images', _image);
  });

  return axios.post(`/manage/products/${id}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// ----------------------------------------------------------------------

export function deleteProduct(id) {
  return axios.delete(`/manage/products/${id}`);
}

export function deleteProducts(ids) {
  return axios.post(`/manage/products/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

// ----------------------------------------------------------------------

export function deleteProductGroup(id) {
  return axios.delete(`/manage/groups/${id}`);
}

export function deleteProductGroups(ids) {
  return axios.post(`/manage/groups/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

// ----------------------------------------------------------------------

export function getProductCountriesOptions() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startCountriesLoading());
      const response = await axios.get('/manage/products/options/countries');
      dispatch(slice.actions.getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCountriesError(error));
    }
  };
}

export function getProductGroupsOptions() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startProductGroupsOptionsLoading());
      const response = await axios.get('/manage/products/options/groups');
      dispatch(slice.actions.getProductGroupsOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasProductGroupsOptionsError(error));
    }
  };
}
