import { Stack, Grid, Skeleton, Box } from '@material-ui/core';
import { PricingPlanRootStyle } from './PricingPlanCard';

const SkeletonCard = () => (
  <PricingPlanRootStyle sx={{ height: 550 }}>
    <Stack spacing={8} sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="50%" sx={{ height: 30, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
      </Stack>

      <Stack spacing={2}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
      </Stack>

      <Stack spacing={2}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
      </Stack>
    </Stack>
  </PricingPlanRootStyle>
);

export const PaymentPlanSkeleton = () => (
  <Grid container spacing={3}>
    {[...new Array(3)].map((_, index) => (
      <Grid item xs={12} md={4} key={index}>
        <Box>
          <SkeletonCard />
        </Box>
      </Grid>
    ))}
  </Grid>
);
