import { Card, Box, Button, Stack, Typography, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import flashCircle from '@iconify/icons-mdi/flash-circle';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { CapabilityPlanLabel } from '../../CapabilityPlanLabel';

const Text = ({ color, children }) => (
  <Typography variant="body2" sx={{ color: color || 'text.secondary' }}>
    {children}
  </Typography>
);

export const CapabilityShippingRate = () => {
  return (
    <Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Card sx={{ p: 2.5, borderRadius: 1, boxShadow: 0, border: (theme) => `solid 1px ${theme.palette.divider}` }}>
        <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
          <CapabilityPlanLabel tier="Standard" />
          <Stack direction="column" spacing={0.5}>
            <Typography variant="subtitle1" sx={{ color: 'secondary.main' }}>
              Tiered Shipping Rates
            </Typography>
            <Text>Control when your shipping rates appear based on your customers shopping cart total</Text>
          </Stack>

          <Box sx={{ pt: 1.5 }}>
            <Button
              variant="outlined"
              size="medium"
              startIcon={<Icon icon={flashCircle} />}
              LinkComponent={RouterLink}
              to={PATH_DASHBOARD.plans.root}
            >
              Upgrade Plan
            </Button>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};
