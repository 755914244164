import { Box, Stack, Divider, Typography } from '@material-ui/core';
import { paramCase, sentenceCase } from 'change-case';
import { fCurrencyFromMinorUnits } from 'src/utils/formatNumber';
import Label from 'src/components/Label';
import { PricingPlanRootStyle } from 'src/components/_admin_v2/subscription/PricingPlanCard';

const TotalRow = ({ title, value, sx, fontSx }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
      <Typography variant="body2" sx={{ color: 'text.secondary', ...fontSx }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', ...fontSx }}>
        {value}
      </Typography>
    </Box>
  );
};

export const PlansPaymentSupportCard = ({ plan, sx }) => {
  if (!plan) {
    return null;
  }

  const { title, subtitle, frequency, priceMonthly, priceYearly, currency } = plan;
  const { symbol } = currency;
  const isMonthly = paramCase(frequency) === 'monthly';
  return (
    <PricingPlanRootStyle sx={{ boxShadow: 'none', border: (theme) => `1px solid ${theme.palette.divider}`, ...sx }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Box>
          <Label color="info" sx={{ padding: 1.5 }}>
            <Typography variant="body2">
              <strong>{`${sentenceCase(frequency)} Plan`}</strong>
            </Typography>
          </Label>
        </Box>
        <Stack spacing={1} alignItems="center">
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <Typography variant="h3">{fCurrencyFromMinorUnits(priceMonthly, symbol)}</Typography>
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            /mo
          </Typography>
        </Box>

        {/* <Divider flexItem /> */}

        <Stack spacing={2} sx={{ width: '100%', p: (theme) => theme.spacing(4, 2, 2) }}>
          <TotalRow
            title="Subtotal"
            value={
              isMonthly ? fCurrencyFromMinorUnits(priceMonthly, symbol) : fCurrencyFromMinorUnits(priceYearly, symbol)
            }
          />
          <Divider flexItem />
          <Stack spacing={1}>
            <TotalRow
              title="Total"
              value={
                isMonthly ? fCurrencyFromMinorUnits(priceMonthly, symbol) : fCurrencyFromMinorUnits(priceYearly, symbol)
              }
            />
            <TotalRow title="Total due today" value={fCurrencyFromMinorUnits('0', symbol)} />
          </Stack>
        </Stack>
      </Stack>
    </PricingPlanRootStyle>
  );
};
