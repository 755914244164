import { createSlice } from '@reduxjs/toolkit';
import { last, defaultTo } from 'lodash';
import { PAGES_OPTIONS } from 'src/pages/admin_v2/editor/utils/editorPages';
import {
  decreaseComponentOrder,
  incrementComponentOrder,
  orderComponents,
  removeComponent
} from 'src/pages/admin_v2/editor/utils/order';
import { serialize } from 'object-to-formdata';

// utils
import axios from '../../utils/axios';
import initial from './initial';

// ----------------------------------------------------------------------

const FIRST_ORDER_ID = 1;

const initialState = {
  loading: {
    storeMetadataIsLoading: false,
    storeThemeLoading: false,
    pagesLoading: false,
    pageLoading: false,
    linksLoading: false,
    linkLoading: false,
    linkPageOptionsLoading: false,
    navigationOptionsLoading: false,
    productOptionsLoading: false,
    productGroupOptionsLoading: false,
    domainsLoading: false
  },
  error: {
    storeMetadataError: null,
    storeThemeError: null,
    pagesError: false,
    pageError: false,
    linksError: false,
    linkError: false,
    linkPageOptionsError: false,
    navigationOptionsError: false,
    productOptionsError: null,
    productGroupOptionsError: null,
    domainsError: null
  },
  storeMetadata: null,
  pages: initial.pagableData,
  page: null,
  links: initial.pagableData,
  link: null,
  domains: {
    hasCustomDomain: false,
    data: []
  },
  domainSchema: null,
  editor: {
    page: PAGES_OPTIONS[0],
    theme: null
  },
  options: {
    products: [],
    productGroups: [],
    linkPages: [],
    navigations: []
  }
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // START LOADING
    startStoreMetadataLoading(state) {
      state.loading.storeMetadataIsLoading = true;
    },
    startStoreThemeLoading(state) {
      state.loading.storeThemeLoading = true;
    },
    startPagesLoading(state) {
      state.loading.pagesLoading = true;
    },
    startDomainsLoading(state) {
      state.loading.domainsLoading = true;
    },
    startDomainSchemaLoading(state) {
      state.loading.domainSchemaLoading = true;
    },
    startPageLoading(state) {
      state.loading.pageLoading = true;
    },
    startLinksLoading(state) {
      state.loading.linksLoading = true;
    },
    startLinkLoading(state) {
      state.loading.linkLoading = true;
    },
    startLinkPageOptionsLoading(state) {
      state.loading.linkPageOptionsLoading = true;
    },
    startNavigationOptionsLoading(state) {
      state.loading.navigationOptionsLoading = true;
    },
    startProductOptionsLoading(state) {
      state.loading.productOptionsLoading = true;
    },
    startProductGroupOptionsLoading(state) {
      state.loading.productGroupOptionsLoading = true;
    },

    // HAS ERROR
    hasStoreMetadataError(state, action) {
      state.loading.storeMetadataIsLoading = false;
      state.error.storeMetadataError = action.payload;
    },
    hasStoreThemeError(state, action) {
      state.loading.storeThemeLoading = false;
      state.error.storeThemeError = action.payload;
    },
    hasDomainsError(state, action) {
      state.loading.domainsLoading = false;
      state.error.domainsError = action.payload;
    },
    hasDomainSchemaError(state, action) {
      state.loading.domainSchemaLoading = false;
      state.error.domainSchemaError = action.payload;
    },
    hasPagesError(state, action) {
      state.loading.pagesLoading = false;
      state.error.pagesError = action.payload;
    },
    hasPageError(state, action) {
      state.loading.pageLoading = false;
      state.error.pageError = action.payload;
    },
    hasLinksError(state, action) {
      state.loading.linksLoading = false;
      state.error.linksError = action.payload;
    },
    hasLinkError(state, action) {
      state.loading.linkLoading = false;
      state.error.linkError = action.payload;
    },
    hasLinkPageOptionsError(state, action) {
      state.loading.linkPageOptionsLoading = false;
      state.error.linkPageOptionsError = action.payload;
    },
    hasNavigationOptionsError(state, action) {
      state.loading.navigationOptionsLoading = false;
      state.error.navigationOptionsError = action.payload;
    },
    hasProductOptionsError(state, action) {
      state.loading.productOptionsLoading = false;
      state.error.productOptionsError = action.payload;
    },
    hasProductGroupOptionsError(state, action) {
      state.loading.productGroupOptionsLoading = false;
      state.error.productGroupOptionsError = action.payload;
    },

    // GET STORE
    getStoreMetadataSuccess(state, action) {
      state.loading.storeMetadataIsLoading = false;
      state.storeMetadata = action.payload;
    },
    getStoreThemeSuccess(state, action) {
      const theme = action.payload;
      theme.home.components = orderComponents(defaultTo(theme?.home?.components, []));
      state.loading.storeThemeLoading = false;
      state.editor.theme = theme;
    },
    getPagesSuccess(state, action) {
      state.loading.pagesLoading = false;
      state.pages = action.payload;
    },
    getDomainsSuccess(state, action) {
      state.loading.domainsLoading = false;
      state.domains = action.payload;
    },
    getDomainSchemaSuccess(state, action) {
      state.loading.domainSchemaLoading = false;
      state.domainSchema = action.payload;
    },
    getPageSuccess(state, action) {
      state.loading.pageLoading = false;
      state.page = action.payload;
    },
    getLinksSuccess(state, action) {
      state.loading.linksLoading = false;
      state.links = action.payload;
    },
    getLinkSuccess(state, action) {
      state.loading.linkLoading = false;
      state.link = action.payload;
    },
    getLinkPageOptionsSuccess(state, action) {
      state.loading.linkPageOptionsLoading = false;
      state.options.linkPages = action.payload;
    },
    getNavigationOptionsSuccess(state, action) {
      state.loading.navigationOptionsLoading = false;
      state.options.navigations = action.payload;
    },
    getProductOptionsSuccess(state, action) {
      state.loading.productOptionsLoading = false;
      state.options.products = action.payload;
    },
    getProductGroupOptionsSuccess(state, action) {
      state.loading.productGroupOptionsLoading = false;
      state.options.productGroups = action.payload;
    },

    // EDITOR
    updateEditorPage(state, action) {
      state.editor.page = action.payload;
    },
    updateGlobalComponent(state, action) {
      const { key, values } = action.payload;
      state.editor.theme.global.theme[key] = {
        ...state.editor.theme.global.theme[key],
        ...values
      };
    },
    updateGlobalColoursComponent(state, action) {
      const { key, values } = action.payload;
      state.editor.theme.global.theme.colours[key] = values;
    },
    updateGlobalMessagesComponent(state, action) {
      const { key, values } = action.payload;
      state.editor.theme.global.messages[key] = values;
    },
    increaseOrderHomeComponentPriority(state, action) {
      const { id } = action.payload;
      const current = state.editor.theme.home;
      const result = incrementComponentOrder(current.components, id);
      if (result) {
        state.editor.theme.home.components = result;
      }
    },
    decreaseHomeComponentPriority(state, action) {
      const { id } = action.payload;
      const current = state.editor.theme.home;
      const result = decreaseComponentOrder(current.components, id);
      if (result) {
        state.editor.theme.home.components = result;
      }
    },
    addHomeComponent(state, action) {
      const component = action.payload;
      // Calculate order position
      const current = state.editor.theme.home;
      const orderedComponents = orderComponents(current.components);
      const lastComponent = last(orderedComponents);
      const nextOrderId = defaultTo(lastComponent?.order, FIRST_ORDER_ID);
      component.order = nextOrderId + 1;
      // Update
      state.editor.theme.home.components.push(component);
    },
    addHomeComponentItem(state, action) {
      const { id, item } = action.payload;
      // Calculate order position
      const current = state.editor.theme.home;
      const index = current.components.findIndex((_component) => _component.id === id);
      if (index !== -1) {
        const items = current.components[index].items;
        const ordered = orderComponents(items);
        const lastComponent = last(ordered);
        const nextOrderId = defaultTo(lastComponent?.order, FIRST_ORDER_ID);
        item.order = nextOrderId + 1;
        // Update
        state.editor.theme.home.components[index].items.push(item);
      }
    },
    updateHomeRoot(state, action) {
      const { key, values } = action.payload;
      const update = {
        ...state.editor.theme.home[key],
        ...values
      };
      state.editor.theme.home[key] = update;
    },
    updateHomeComponent(state, action) {
      const { id, values } = action.payload;
      const current = state.editor.theme.home;
      const components = current.components;
      const index = components.findIndex((_component) => _component.id === id);
      if (index !== -1) {
        const update = {
          ...components[index],
          ...values
        };
        state.editor.theme.home.components[index] = update;
      }
    },
    updateHomeComponentItem(state, action) {
      const { componentId, id, values } = action.payload;
      const current = state.editor.theme.home;
      const index = current.components.findIndex((_component) => _component.id === componentId);
      if (index !== -1) {
        const itemIndex = current.components[index].items.findIndex((_item) => _item.id === id);
        if (itemIndex !== -1) {
          const update = {
            ...current.components[index].items[itemIndex],
            ...values
          };
          state.editor.theme.home.components[index].items[itemIndex] = update;
        }
      }
    },
    updateHomeComponentItems(state, action) {
      const { id, newItems } = action.payload;
      const current = state.editor.theme.home;
      const index = current.components.findIndex((_component) => _component.id === id);
      if (index !== -1) {
        const update = {
          ...current.components[index],
          items: newItems
        };
        state.editor.theme.home.components[index] = update;
      }
    },
    updateLayoutComponent(state, action) {
      const { id, values } = action.payload;
      const update = {
        ...state.editor.theme.layout[id],
        ...values
      };
      state.editor.theme.layout[id] = update;
    },
    updateComponentLayout(state, action) {
      const { component, key, values } = action.payload;
      const current = state.editor.theme[component].layout[key];
      const update = {
        ...current,
        ...values
      };
      state.editor.theme[component].layout[key] = update;
    },
    updateSubComponentLayout(state, action) {
      const { component, sub, key, values } = action.payload;
      const current = state.editor.theme[component][sub].layout[key];
      const update = {
        ...current,
        ...values
      };
      state.editor.theme[component][sub].layout[key] = update;
    },
    updateProductsComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.products[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.products[key] = update;
    },
    updateCollectionComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.collection[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.collection[key] = update;
    },
    updateCollectionsComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.collections[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.collections[key] = update;
    },
    updateProductComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.product[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.product[key] = update;
    },
    deleteHomeComponent(state, action) {
      const { id } = action.payload;
      const current = state.editor.theme.home;
      const result = removeComponent(current.components, id);
      if (result) {
        state.editor.theme.home.components = result;
      }
    },
    deleteHomeComponentItem(state, action) {
      const { componentId, id } = action.payload;
      const current = state.editor.theme.home;
      const index = current.components.findIndex((_component) => _component.id === componentId);
      if (index !== -1) {
        const items = current.components[index].items;
        const result = removeComponent(items, id);
        if (result) {
          state.editor.theme.home.components[index].items = result;
        }
      }
    },
    updateCartComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.cart[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.cart[key] = update;
    },
    updateCheckoutComponent(state, action) {
      const { key, values } = action.payload;
      if (key) {
        const component = state.editor.theme.checkout[key];
        const update = {
          ...component,
          ...values
        };
        state.editor.theme.checkout[key] = update;
      } else {
        state.editor.theme.checkout = values;
      }
    },
    updateContactComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.contact[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.contact[key] = update;
    },
    updateCustomPageComponent(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.custom[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.custom[key] = update;
    },
    updateError404Component(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.error.notFound[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.error.notFound[key] = update;
    },
    updateError500Component(state, action) {
      const { key, values } = action.payload;
      const component = state.editor.theme.error.server[key];
      const update = {
        ...component,
        ...values
      };
      state.editor.theme.error.server[key] = update;
    },
    // RESET
    resetStoreMetadata(state) {
      state.loading.storeMetadataIsLoading = false;
      state.error.storeMetadataError = null;
      state.storeMetadata = null;
    },
    resetPages(state) {
      state.loading.pagesLoading = false;
      state.error.pagesError = null;
      state.pages = initial.pagableData;
    },
    resetPage(state) {
      state.loading.pageLoading = false;
      state.error.pageError = null;
      state.page = null;
    },
    resetLinks(state) {
      state.loading.linksLoading = false;
      state.error.linksError = null;
      state.links = initial.pagableData;
    },
    resetLink(state) {
      state.loading.linkLoading = false;
      state.error.linkError = null;
      state.link = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  updateGlobalComponent,
  updateGlobalColoursComponent,
  updateGlobalMessagesComponent,
  updateHomeRoot,
  addHomeComponent,
  addHomeComponentItem,
  updateEditorPage,
  updateHomeComponent,
  updateHomeComponentItem,
  updateHomeComponentItems,
  deleteHomeComponent,
  deleteHomeComponentItem,
  updateLayoutComponent,
  updateComponentLayout,
  updateSubComponentLayout,
  updateProductsComponent,
  updateProductComponent,
  updateCollectionComponent,
  updateCollectionsComponent,
  updateCartComponent,
  updateCheckoutComponent,
  updateContactComponent,
  updateCustomPageComponent,
  updateError404Component,
  updateError500Component,
  increaseOrderHomeComponentPriority,
  decreaseHomeComponentPriority,
  resetStoreMetadata,
  resetPages,
  resetPage,
  resetLinks,
  resetLink
} = slice.actions;

// ----------------------------------------------------------------------

const formDataOptions = {
  indices: true,
  allowEmptyArrays: true,
  // Needed so we can send array with all keys. Without it will ignore arrays
  noAttributesWithArrayNotation: false,
  dotsForObjectNotation: true,
  // Needed to send values with null rather than omitting which can cause BE problems
  nullsAsUndefineds: false
};

export function deployStoreConfiguration(values) {
  const formData = serialize(values, formDataOptions);
  return axios.put('/manage/store/theme', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// ----------------------------------------------------------------------

export function getStoreMetadata() {
  return async (dispatch) => {
    dispatch(slice.actions.startStoreMetadataLoading());
    try {
      const response = await axios.get(`/manage/store`);
      dispatch(slice.actions.getStoreMetadataSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasStoreMetadataError(error));
    }
  };
}

export function updateStoreMetadata(values) {
  const options = {
    indices: true,
    allowEmptyArrays: false,
    dotsForObjectNotation: true,
    // Needed to send values with null rather than omitting which can cause BE problems
    nullsAsUndefineds: true
  };
  const formData = serialize(values, options);
  return axios.put('/manage/store', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// ----------------------------------------------------------------------

export function closeStore() {
  return axios.delete('/manage/store');
}

// ----------------------------------------------------------------------

// DOMAINS

export function getDomains() {
  return async (dispatch) => {
    dispatch(slice.actions.startDomainsLoading());
    try {
      const response = await axios.get(`/manage/domains`);
      dispatch(slice.actions.getDomainsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasDomainsError(error));
    }
  };
}

export function createDomain(domain) {
  return axios.post(`/manage/domains`, {
    hostname: domain
  });
}

export function getDomainSchema() {
  return async (dispatch) => {
    dispatch(slice.actions.startDomainSchemaLoading());
    try {
      const response = await axios.get(`/manage/domains/connect`);
      const schemas = response.data.schemas;
      dispatch(slice.actions.getDomainSchemaSuccess(schemas));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasDomainSchemaError(error));
    }
  };
}

export function verifyDomain(domain) {
  return axios.get(`/manage/domains/${domain}`);
}

export function deleteDomain(id) {
  return axios.delete(`/manage/domains/${id}`);
}

// ----------------------------------------------------------------------

// PAGES

export function getPages(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startPagesLoading());
    try {
      const response = await axios.get(`/manage/store/pages`, {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getPagesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasPagesError(error));
    }
  };
}

export function getPage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startPageLoading());
    try {
      const response = await axios.get(`/manage/store/pages/${id}`);
      dispatch(slice.actions.getPageSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasPageError(error));
    }
  };
}

export async function createPage(values) {
  return axios.post('/manage/store/pages', values);
}

export async function updatePage(id, values) {
  return axios.put(`/manage/store/pages/${id}`, values);
}

export function deletePage(id) {
  return axios.delete(`/manage/store/pages/${id}`);
}

export function deletePages(ids) {
  return axios.post(`/manage/store/pages/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

// ----------------------------------------------------------------------

// Links

export function getLinks(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLinksLoading());
    try {
      const response = await axios.get(`/manage/store/links`, {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getLinksSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasLinksError(error));
    }
  };
}

export function getLink(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLinkLoading());
    try {
      const response = await axios.get(`/manage/store/links/${id}`);
      dispatch(slice.actions.getLinkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasLinkError(error));
    }
  };
}

export async function createLink(values) {
  return axios.post('/manage/store/links', values);
}

export async function updateLink(id, values) {
  return axios.put(`/manage/store/links/${id}`, values);
}

export function deleteLink(id) {
  return axios.delete(`/manage/store/links/${id}`);
}

export function deleteLinks(ids) {
  return axios.post(`/manage/store/links/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

// LINK ITEMS

export async function createLinkItem(id, values) {
  return axios.post(`/manage/store/links/${id}/items`, values);
}

export async function updateLinkItem(id, linkItemId, values) {
  return axios.put(`/manage/store/links/${id}/items/${linkItemId}`, values);
}

export function deleteLinkItem(id, linkItemId) {
  return axios.delete(`/manage/store/links/${id}/items/${linkItemId}`);
}

// OPTIONS

export function getPagesOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLinkPageOptionsLoading());
    try {
      const response = await axios.get(`/manage/options/pages`);
      dispatch(slice.actions.getLinkPageOptionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasLinkPageOptionsError(error));
    }
  };
}

export function getNavigationOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startNavigationOptionsLoading());
    try {
      const response = await axios.get(`/manage/options/links`);
      dispatch(slice.actions.getNavigationOptionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasNavigationOptionsError(error));
    }
  };
}

export function getStoreTheme() {
  return async (dispatch) => {
    dispatch(slice.actions.startStoreThemeLoading());
    try {
      const response = await axios.get(`/manage/store/theme`);
      dispatch(slice.actions.getStoreThemeSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasStoreThemeError(error));
    }
  };
}

export function getProductOptions(status) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductOptionsLoading());
    try {
      const response = await axios.get(`/manage/products/options`, {
        params: {
          status
        }
      });
      dispatch(slice.actions.getProductOptionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasProductOptionsError(error));
    }
  };
}

// TODO: duplicate, deprecate and replace with products -> options
export function getProductGroupOptions(status) {
  return async (dispatch) => {
    dispatch(slice.actions.startProductGroupOptionsLoading());
    try {
      const response = await axios.get('/manage/products/options/groups', {
        params: {
          status
        }
      });
      dispatch(slice.actions.getProductGroupOptionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasProductGroupOptionsError(error));
    }
  };
}
