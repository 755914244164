// material
import { useCallback, useState } from 'react';
import { defaultTo, filter } from 'lodash';
import { Grid, Switch, Typography, Stack, Box } from '@material-ui/core';
import { paramCase } from 'change-case';
import PricingPlanCard from './PricingPlanCard';
import { PaymentPlanSkeleton } from './PaymentPlanSkeleton';

// ----------------------------------------------------------------------

export default function PricingPlans({ isLargeView, isLoggedIn, loading, plans, currentPlan, handleAction }) {
  const [monthly, setMonthly] = useState(false);

  const getPlans = useCallback(() => {
    if (monthly) {
      return filter(defaultTo(plans, []), (o) => paramCase(defaultTo(o.frequency, '')) === 'monthly');
    }
    return filter(defaultTo(plans, []), (o) => paramCase(defaultTo(o.frequency, '')) === 'yearly');
  }, [plans, monthly]);

  return loading ? (
    <Box>
      <PaymentPlanSkeleton />
    </Box>
  ) : (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography
          variant="overline"
          sx={{ mr: 1.5, ...(monthly && { color: (theme) => theme.palette.primary.main }) }}
        >
          Monthly
        </Typography>
        <Switch defaultChecked={!monthly} onChange={() => setMonthly((prev) => !prev)} />
        <Box>
          <Typography
            variant="overline"
            sx={{ mr: 1.5, ...(!monthly && { color: (theme) => theme.palette.primary.main }) }}
          >
            Yearly
          </Typography>
          <Typography
            gutterBottom
            component="span"
            variant="overline"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            (Save 25%)
          </Typography>
        </Box>
      </Stack>

      <Box>
        <Grid container spacing={3}>
          {getPlans().map((plan) => (
            <Grid item xs={12} md={4} key={plan.id}>
              <PricingPlanCard
                isLargeView={isLargeView}
                isLoggedIn={isLoggedIn}
                plan={plan}
                currentPlan={currentPlan}
                handleAction={handleAction}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
