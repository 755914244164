import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import SubscriptionPlanTrialNotification from 'src/guards/SubscriptionPlanTrialNotification';
// hooks
import CapabilityGuard from 'src/guards/CapabilityGuard';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import AdminNavbar from './AdminNavbar';
import { COLLAPSE_WIDTH } from '../admin-store/constants';
import AdminSettingsSidebar from './AdminSettingsSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function AdminSettingsLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <AdminNavbar onOpenSidebar={() => setOpen(true)} />
      <AdminSettingsSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: `${COLLAPSE_WIDTH}px`
          })
        }}
      >
        <SubscriptionPlanTrialNotification />
        <CapabilityGuard>
          <Outlet />
        </CapabilityGuard>
      </MainStyle>
    </RootStyle>
  );
}
