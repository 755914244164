import { paramCase } from 'change-case';
import { defaultTo, max } from 'lodash';
import {
  ADVANCED,
  STANDARD,
  BASIC,
  CURRENCY_ADVANCED_CAPABILITY,
  REPORTS_ADVANCED_CAPABILITY,
  REPORTS_BASIC_CAPABILITY,
  REPORTS_LIST_PAGE,
  REPORTS_OVERVIEW_PAGE,
  CUSTOMERS_PAGE,
  REPORTS_STANDARD_CAPABILITY,
  SHIPPING_ADVANCED_CAPABILITY,
  SHIPPING_BASIC_CAPABILITY,
  SHIPPING_STANDARD_CAPABILITY,
  USERS_ADVANCED_CAPABILITY,
  USERS_BASIC_CAPABILITY,
  USERS_STANDARD_CAPABILITY,
  CUSTOMERS_STANDARD_CAPABILITY,
  MESSAGES_STANDARD_CAPABILITY,
  MESSAGES_PAGE,
  SHIPPING_RATES_STANDARD_CAPABILITY
} from './capabilityConstants';

export const isAdvanced = (tier) => paramCase(defaultTo(tier, '')) === 'advanced';

// What capabilities are available for the given tier
export const getTierCapabilities = (value) => {
  const tier = paramCase(defaultTo(value, ''));
  const advancedCapabilities = [
    REPORTS_ADVANCED_CAPABILITY,
    USERS_ADVANCED_CAPABILITY,
    SHIPPING_ADVANCED_CAPABILITY,
    CURRENCY_ADVANCED_CAPABILITY,
    CUSTOMERS_STANDARD_CAPABILITY,
    MESSAGES_STANDARD_CAPABILITY,
    SHIPPING_RATES_STANDARD_CAPABILITY
  ];
  if (tier === ADVANCED) {
    return advancedCapabilities;
  }
  if (tier === STANDARD) {
    return [
      REPORTS_STANDARD_CAPABILITY,
      USERS_STANDARD_CAPABILITY,
      SHIPPING_STANDARD_CAPABILITY,
      CUSTOMERS_STANDARD_CAPABILITY,
      MESSAGES_STANDARD_CAPABILITY,
      SHIPPING_RATES_STANDARD_CAPABILITY
    ];
  }
  if (tier === BASIC) {
    return [REPORTS_BASIC_CAPABILITY, USERS_BASIC_CAPABILITY, SHIPPING_BASIC_CAPABILITY];
  }
  // TRIAL - Needs to have all offers before signing up
  return advancedCapabilities;
};

// Who can access a given page (capabilties)
export const getPageCapabilities = (resource) => {
  if (!resource) {
    return [];
  }
  if (resource === REPORTS_OVERVIEW_PAGE) {
    return [REPORTS_ADVANCED_CAPABILITY.key, REPORTS_STANDARD_CAPABILITY.key];
  }
  if (resource === REPORTS_LIST_PAGE) {
    return [REPORTS_ADVANCED_CAPABILITY.key];
  }
  if (resource === CUSTOMERS_PAGE) {
    return [CUSTOMERS_STANDARD_CAPABILITY.key];
  }
  if (resource === MESSAGES_PAGE) {
    return [MESSAGES_STANDARD_CAPABILITY.key];
  }
  return [];
};

export const isCapabilityLimitViolated = (input, limit) => defaultTo(input, 0) >= defaultTo(limit, 0);

export const calculateMaxValue = (value, limit) => max([(value * 100) / limit, 0]);
